import Vue from 'vue';
//组件库
import { Button,NavBar,Toast,Tab, Tabs,Swipe, SwipeItem } from 'vant';
Vue.use(Button).use(NavBar).use(Toast).use(Tab).use(Tabs).use(Swipe).use(SwipeItem);

//视频插件

import VideoPlayer from 'vue-video-player';
require('video.js/dist/video-js.css');
require('vue-video-player/src/custom-theme.css');
Vue.use(VideoPlayer);