import Vue from 'vue';
import Ripple from 'vue-ripple-directive'; //水波纹指令
import { debounce,throttle} from "../utils";
//配置按钮水波纹
Ripple.color = 'rgba(255,250,240,.35)';
Ripple.zIndex = 55;
const config = {
           bind(el, binding) {
            let val = binding.value,
                wait = 500,
                immediate = false,
                type = 'click',
                params = [],
                func,
                handle = binding.name === 'debounce' ? debounce : throttle;
            if (val === null) return;
            if (typeof val !== "object" && typeof val !== "function") return;
            if (binding.arg) wait = +binding.arg;
            if (binding.modifiers && binding.modifiers.immediate) immediate = true;
            if (typeof val === "function") func = val;
            if (typeof val === "object") {
                func = val.func || function () {};
                type = val.type || 'click';
                params = val.params || [];
            }
            el.$type = type;
            el.$handle = handle(function proxy(...args) {
                return func.call(this, ...params.concat(args));
            }, wait, immediate);
            el.addEventListener(el.$type, el.$handle);
        },
        unbind(el) {
            el.removeEventListener(el.$type, el.$handle);
        }
    };
Vue.directive('throttle',config);
Vue.directive('debounce',config);
Vue.directive('ripple', Ripple);
