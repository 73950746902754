<template>
  <div class="text">
    <div class="header">
      {{ title }}
    </div>
    <div class="content">
      <div
        v-for="(item,index) of list "
        :key="index"
      >
        <div>
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainContent",
  props:{
      title:{
          type:String,
          default:'',
      },
      content:{
          type:String,
          default:'',
      }
  },
   data(){
    return {

      }
   },
  computed:{
      list({content}){
        if(content) {
          let reg = /\r+/g
          let list =  content.split(reg)
          return list
        }else{
          return '';
        }


      }
  },

};
</script>

<style scoped lang="scss">
    .text{
        margin-bottom: 17px;
      .header{
        margin-bottom: 9px;
        font-weight: $f_w6;
        font-size:$fz16;
        color:$c_3;
      }
      .content{
        font-size: $fz14;
        color:$c_6;
        line-height: 25px;
      }
    }
</style>