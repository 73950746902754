<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  created() {

  }
};
</script>
<style lang="scss">
#app {
  height: 100%;
}


</style>
