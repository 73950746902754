import Vue from 'vue';
import day from 'dayjs';

//全局过滤器;
//时间格式化
Vue.filter('formatDate', (time, fmt) => {
    if (fmt) {
        return  day(time).format(fmt);
    } else {
        return  day(time).format('YYYY-MM-DD');
    }
});