<template>
  <div class="middle">
    <p class="middle-left">
      备课要点
    </p>
    <p
      v-ripple
      class="middle-right"
    >
      {{ curr + 1}} / {{ total }}
    </p>
  </div>
</template>

<script>
export default {
  name: "MainMiddle",
  props:{
    curr:{
      type:[Number,String],
      default:1,
    },
    total:{
      type:[Number,String],
      default:0,
    }
  },
  methods:{

  }
};
</script>

<style scoped lang="scss">
      .middle{
        width: 375px;
        height: 52px;
        background: $b_f;
        padding: 0 21px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom:1px solid $f_e;
        box-shadow:0 1px 8px 0 $f_e;
        &-left{
          font-size: 19px;
          font-weight: 400;
          color: $c_3;
        }
        &-right{
          width: 71px;
          height: 26px;
          border:1px solid $t_orange;
          color:$t_orange;
          border-radius: 12px;
          box-sizing: border-box;
          font-size: $fz14;
          @include center();
        }
      }
</style>