import { get } from '../../core/http';
//http://192.168.2.118:8888/v8/guides/?instrument_type=5

let  apiParams = {
        list:{
             url:'/guides/',
             params:{
                 instrument_type:15,
             }
        }
};
const getList = ()=>{
    return get(apiParams.list);
};
export default {
    //获取列表
    getList
};