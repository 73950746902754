import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import 'lib-flexible';
import './styles/index.scss';
import "./plugins";  //插件
import "./filters";  //全局过滤器
import "./directives";  //自定义指令


Vue.config.productionTip = false;





new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
