import axios from 'axios';
import { Toast } from 'vant';
// 默认配置
let projectName = 'v8';
let server = axios.create({
    baseURL: process.env.VUE_APP_BASE_API + projectName,
    headers: { 'content-type': 'application/json', 'tenant': '0000' },
    timeout: 60 * 1000, // 请求超时时间
});


server.interceptors.request.use(config => {
    return config;
    },
    error => {
        return Promise.reject(error);
    }
);
// 响应拦截
server.interceptors.response.use(
    response => {
        const { data } = response;
        if (data.error !== 0) {
            Toast(data.msg);
            return Promise.reject(response);
        } else {
            return Promise.resolve(data.data);
        }
    },
    error => {
        return Promise.reject(error);
    }
);

export default server;