
<script>
export default {
  name: "MainImage",
  props:{
    src:{
      type:String,
      default:''
    }
  },
  render(h){
      return h('div',{
        class:{
          'image-wrapper':true,
        }
      },[
          h('img',{
            attrs:{
              src:this.src,
            }
          })
      ])
  }
};
</script>

<style scoped lang="scss">
      .image-wrapper{
        width: 375px;
        height: 211px;
        img{
          width: 100%;
          height: 100%;
        }
      }
</style>