<template>
  <div class="home">
    <div class="main">
      <div class="main-page">
        <van-swipe
          ref="videoSwipe"
          class="my-swipe"
          :show-indicators="false"
          @change="swipeChange"
        >
          <van-swipe-item
            v-for="(item,index) of data"
            :key="index"
          >
            <div v-if="item.item_type === 0">
              <main-image :src="item.cover_url" />
            </div>
            <div
              v-else
              class="video-wrapper"
            >
              <main-video
                ref="video"
                :src="item.video_url"
                :cover="item.cover_url"
                @on-play="playVideo"
                @on-pause="pauseVideo"
              />
            </div>
            <div
              v-if="isMore"
              class="more"
            >
              <div class="more_img">
                <img
                  src="../../assets/home/video-hand.png"
                  alt=""
                >
              </div>
              <p class="more_text">
                左滑选择更多
              </p>
            </div>
          </van-swipe-item>
        </van-swipe>
        <main-middle
          :curr="active"
          :total="data.length"
        />
        <div class="container">
          <van-swipe
            ref="textSwipe"
            class="my-swipe"
            :show-indicators="false"
            @change="swipeTextChange"
          >
            <van-swipe-item
              v-for="(item,index) of data"
              :key="index"
            >
              <main-content
                :title="item.name"
                :content="item.content"
              />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /srcx

import MainMiddle from "./components/MainMiddle";
import MainContent from "./components/MainContent";
import MainVideo from "./components/MainVideo";
import MainImage from "./components/MainImage";
import http from '@/api/home';
import {getUrlParam} from '@/utils';

export default {
  name: "Home",
  components: {
    MainMiddle,
    MainContent,
    MainVideo,
    MainImage
  },
  data() {
    return {
      data: [],
      active: 0,
      isMore: true,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //播放
    playVideo() {
      this.isMore = false;
    },
    //暂停
    pauseVideo() {
      this.isMore = true;
    },
    swipeTextChange(index){
      this.active = index;
      this.isMore = true;
      this.$refs.videoSwipe.swipeTo(index, {immediate: true});
      //切换的时候需要全部都暂停  否则上一个在仍在播放中
      this.$refs.video.forEach((item) => {
        item.player.pause();
      });
    },
    swipeChange(index) {
      this.active = index;
      this.isMore = true;
      this.$refs.textSwipe.swipeTo(index, {immediate: true});
      //切换的时候需要全部都暂停  否则上一个在仍在播放中
      this.$refs.video.forEach((item) => {
        item.player.pause();
      });
    },
    format(arr, course_id, id) {
      let data = []
      if (Array.isArray(arr)) {
        let array = arr.flat();
        array.forEach((item) => {
          if (item.label === 0 && item.course_id === Number(course_id)) {
            item.video_list.forEach((val) => {
              if (val._id === id) {
                if (val.item_list) {
                  data = val.item_list
                }
              }
            })
          }
        });
        return data;
      } else {
        return [];
      }
    },

    getList() {
      http.getList().then(res => {
        let course_id = getUrlParam('course_id') || '';
        let _id = getUrlParam('_id') || '';
        this.data = this.format(res.guides, course_id, _id);
        if (this.data.length === 1) {
          this.isMore = false;
        }
      });
    }
  }
};
</script>


<style scoped lang="scss">
.home {
  ::v-deep .van-tabs__wrap {
    display: none;
  }

  .nav-button {
    border: 1px solid $t_orange;
    color: $t_orange;
    font-size: $fz12;
    background: #FFF0EB;
    border-radius: 5px;
    @include layout(47px, 24px);
    @include center();
  }

  .main {
    .main-page {
      .video-wrapper {
        width: 375px;
        height: 211px;
        position: relative;
        background: $b_d8;

      }
      .more {
        height: 24px;
        border-radius: 14px;
        position: absolute;
        right: 8px;
        top: 6px;
        background: rgba(51, 51, 51, .75);
        display: flex;
        align-items: center;

        .more_img {
          width: 16px;
          height: 17px;
          line-height: 17px;
          padding-left: 7px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .more_text {
          font-size: $fz12;
          font-weight: 400;
          color: #FFFFFF;
          padding: 0 7px;
        }
      }
    }

    .container {
      padding: 29px 21px 0;
    }
  }
}
</style>
