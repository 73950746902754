import request from './request';

export const get = (options) => {
    return request({
        url: options.url,
        method: 'get',
        params: options.params,
    }).then(res => {
        return res;
    });
};

export const post = (options) => {
    return request({
        url: options.url,
        method: 'post',
        data: options.data,
    });
};
